<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-if="getHome">
        <router-link :to="{ name: getHome }">
          <span>Dashboard</span>
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="breadcrumb.name"
        v-for="breadcrumb in breadcrumbList"
      >
        <router-link :to="routeTo(breadcrumb)">
          {{ breadcrumb.text || $t(`pageTitle.${breadcrumb.name}`) }}
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$slots.lastPage">
        <slot name="lastPage"></slot>
      </el-breadcrumb-item>
      <slot name="restPage" />
    </el-breadcrumb>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import roleMixin from "@/mixins/role.js";

export default {
  props: ["customBreadcrumbList"],
  mixins: [roleMixin],
  data() {
    return { breadcrumbList: [] };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    isLogin() {
      return this.token !== "";
    },
    getHome() {
      if (this.isStudents) {
        return "StudentHome";
      } else if (this.isEducators) {
        return "EducatorHome";
      } else if (this.isSuperAdmin) {
        return "SuperAdminHome";
      } else {
        return null;
      }
    },
    isSuperAdmin() {
      if (this.$route.meta && this.$route.meta.role) {
        if (this.$route.meta.role === "super_admin") {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.isLogin) {
          if (this.isRoleAdmin()) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    isEducators() {
      if (this.$route.meta && this.$route.meta.role) {
        if (this.$route.meta.role === "educator") {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.isLogin) {
          if (this.isRoleTeacher()) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    isStudents() {
      if (this.$route.meta && this.$route.meta.role) {
        if (this.$route.meta.role === "student") {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.isLogin) {
          if (this.isRoleStudent()) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    customBreadcrumbList() {
      this.updateBreadcrumb();
    },
    $route() {
      this.updateBreadcrumb();
    }
  },
  created() {
    this.updateBreadcrumb();
  },
  methods: {
    routeTo(breadcrumb) {
      if (breadcrumb.path) {
        return { path: breadcrumb.path };
      }
      return { name: breadcrumb.name };
    },
    updateBreadcrumb() {
      this.breadcrumbList = this.filterRole(
        this.customBreadcrumbList || this.$route.meta.breadcrumb
      );
    },
    filterRole(breadcrumbList) {
      // if (this.isRoleAdmin()) return breadcrumbList;
      return (breadcrumbList || []).filter(
        breadcrumb => breadcrumb.name !== "adminHome"
      );
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  /* margin: 1.8rem 0; */
  /* margin-bottom: 1.8rem; */
}
::v-deep .el-breadcrumb {
  font-size: 16px;
}

@media screen and (max-width: 736px) {
  ::v-deep .el-breadcrumb {
    font-size: 12px;
  }
  ::v-deep .el-breadcrumb__separator {
    margin: 0;
  }
}
</style>
